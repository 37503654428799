import React from 'react';
import LandingPageView2 from 'views/LandingPageView2';
import Main from 'layouts/Main';
import WithLandingPageLayout from 'WithLandingPageLayout';
import { Helmet } from 'react-helmet';

// DATA

const title = 'Save on S3 Storage Pricing';
const content = {
  hero: {
    title: "Stop paying too much for",
    emphasized: "AWS S3 Bucket Storage",
    subtitle: "There is a better way to save on Cloud Storage",
    titleVariant: "h2",
    subtitleVariant: "h4",      
  },
  quoteForm: {
    quote_provider:"aws",
    title: "Save 60% or more on bucket storage from leading providers",  
    subtitle: "Companies bid for your business!",
    titleVariant: "h4",
    subtitleVariant: "h5",
    refPage: "s3-storage-savings",   
  },
  subText: {
    title: "Same AWS S3 bucket storage",
    emphasized: "but cheaper.",
    subtitle: "We will take your requirements and source competitive quotes from leading partners and Managed Service Providers, saving you up to 60% on the same AWS S3 bucket storage you use today. You decide who to deal with and when, your information stays anonymous in the meantime.",
    subtitleEmphasized: "Request a quote today, it’s simple and free!",
    titleVariant: "h5",
    subtitleVariant: "h6",     
  }
};

// END DATA

const LandingPage = () => {
  return (
  <>
  <Helmet
    defaultTitle="WindRate | #1 Cloud Storage Pricing Engine"
    title={title}
    titleTemplate="%s | WindRate.com"
  />     
    <WithLandingPageLayout
      data={content}
      component={LandingPageView2}
      layout={Main}
    />
    </>
  )
};
 
 export default LandingPage;
 